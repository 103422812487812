import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import './styles/discoverConcentrations.scss'


function DiscoverConcentrations({ degree }){
    if(degree === "psych") {
        return (
            <div id="discoverConcentrations">
                <Container fluid="lg" className="py-xl-5 py-4 my-5">
                    <h2 className="mb-4 px-2 px-lg-0">Concentrations</h2>
                    <Carousel variant="dark" className="d-none d-xl-block">
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/business-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Business Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Business Psychology</Card.Title>
                                            <Card.Text>The Business Psychology concentration at Meridian is designed for those who seek to redefine the landscape of organizational behavior and leadership through a lens of group facilitation and psychological depth.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/clinical-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Clinical Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Clinical Psychology</Card.Title>
                                            <Card.Text>The Clinical Psychology concentration emphasizes coursework aligned with the practice of psychotherapy in contexts such as community clinics, private practice, employee assistance programs, and forensic contexts.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/collective-trauma-and-social-healing-icon.svg" width="80" height="80" className="mb-4" alt="Collective Trauma and Social Healing Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Collective Trauma and Social Healing</Card.Title>
                                            <Card.Text>The Collective Trauma and Social Healing concentration is intended for Master's and Doctoral students in the Psychology and Education programs who aspire to be social healing practitioners in the context of their professional goals.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/depth-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Depth Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Depth Psychology</Card.Title>
                                            <Card.Text>The Depth Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, depth consultation in organizations, career counseling, teaching.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/organizational-development-icon.svg" width="80" height="80" className="mb-4" alt="Organizational Development Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Organizational Development</Card.Title>
                                            <Card.Text>The Organizational Development concentration emphasizes coursework aligned with the practice of organizational leadership and development in various institutional domains, like: Business, Non-profits (NGOs); and government including sectors such as education, law, and the arts.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/developmental-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Developmental Coaching Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Developmental Coaching</Card.Title>
                                            <Card.Text>The Developmental Coaching concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/expressive-and-movement-arts-icon.svg"  width="80" height="80" className="mb-4" alt="Expressive and Movement Arts Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Expressive and Movement Arts</Card.Title>
                                            <Card.Text>The Expressive and Movement Arts concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, employee wellness programs.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/health-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Health and Wellbeing Coaching Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Health and Wellbeing Coaching</Card.Title>
                                            <Card.Text>The Health and Wellbeing Coaching concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, and employee wellness programs.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/integral-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Integral Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Integral Psychology</Card.Title>
                                            <Card.Text>The Integral Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, depth consultation in organizations, career counseling, and teaching.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/somatic-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Somatic Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Somatic Psychology</Card.Title>
                                            <Card.Text>The Somatic Psychology concentration emphasizes coursework aligned with professional practice in contexts such as somatic education, psychotherapy practice, Health and Wellbeing Coaching, employee wellness programs. The approach to learning somatic psychology at Meridian University is rooted in the transformative learning paradigm at the heart of Meridian’s programs, with a particular emphasis on embodied approaches to teaching and learning.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-leadership-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Leadership Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transformative Leadership</Card.Title>
                                            <Card.Text>The Transformative Leadership concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-learning-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Learning Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transformative Learning</Card.Title>
                                            <Card.Text>The Transformative Learning concentration emphasizes coursework aligned with professional practice in contexts such as education, organizational leadership, and adult education.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transpersonal-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Transpersonal Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transpersonal Psychology</Card.Title>
                                            <Card.Text>The Transpersonal Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, consultation to organizations, career counseling, teaching.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/yoga-and-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Integral Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Yoga and Psychology</Card.Title>
                                            <Card.Text>The Yoga and Psychology concentration is intended for master's and doctoral students in the Psychology Program who wish to have more expertise on the relationship between Yoga and Psychology in the context of their professional goals. The concentration emphasizes coursework aligned with professional practice in contexts such as yoga therapy, yoga teaching, psychotherapy practice, somatic education, and somatic research.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                    <div className="d-block d-xl-none px-2 px-lg-0">
                        <Row>
                            <Col xs={12} md={6} className='mb-4'>
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/business-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Business Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Business Psychology</Card.Title>
                                        <Card.Text>The Business Psychology concentration at Meridian is designed for those who seek to redefine the landscape of organizational behavior and leadership through a lens of group facilitation and psychological depth.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/clinical-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Clinical Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Clinical Psychology</Card.Title>
                                        <Card.Text>The Clinical Psychology concentration emphasizes coursework aligned with the practice of psychotherapy in contexts such as community clinics, private practice, employee assistance programs, and forensic contexts.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/collective-trauma-and-social-healing-icon.svg" width="80" height="80" className="mb-4" alt="Collective Trauma and Social Healing Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Collective Trauma and Social Healing</Card.Title>
                                        <Card.Text>The Collective Trauma and Social Healing concentration is intended for Master's and Doctoral students in the Psychology and Education programs who aspire to be social healing practitioners in the context of their professional goals.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/depth-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Depth Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Depth Psychology</Card.Title>
                                        <Card.Text>The Depth Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, depth consultation in organizations, career counseling, teaching.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/organizational-development-icon.svg" width="80" height="80" className="mb-4" alt="Organizational Development Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Organizational Development</Card.Title>
                                        <Card.Text>The Organizational Development concentration emphasizes coursework aligned with the practice of organizational leadership and development in various institutional domains, like: Business, Non-profits (NGOs); and government including sectors such as education, law, and the arts.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/developmental-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Developmental Coaching Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Developmental Coaching</Card.Title>
                                        <Card.Text>The Developmental Coaching concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/expressive-and-movement-arts-icon.svg"  width="80" height="80" className="mb-4" alt="Expressive and Movement Arts Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Expressive and Movement Arts</Card.Title>
                                        <Card.Text>The Expressive and Movement Arts concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, employee wellness programs.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/health-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Health and Wellbeing Coaching Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Health and Wellbeing Coaching</Card.Title>
                                        <Card.Text>The Health and Wellbeing Coaching concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, and employee wellness programs.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/integral-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Integral Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Integral Psychology</Card.Title>
                                        <Card.Text>The Integral Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, depth consultation in organizations, career counseling, and teaching.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/somatic-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Somatic Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Somatic Psychology</Card.Title>
                                        <Card.Text>The Somatic Psychology concentration emphasizes coursework aligned with professional practice in contexts such as somatic education, psychotherapy practice, Health and Wellbeing Coaching, employee wellness programs. The approach to learning somatic psychology at Meridian University is rooted in the transformative learning paradigm at the heart of Meridian’s programs, with a particular emphasis on embodied approaches to teaching and learning.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-leadership-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Leadership Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transformative Leadership</Card.Title>
                                        <Card.Text>The Transformative Leadership concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-learning-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Learning Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transformative Learning</Card.Title>
                                        <Card.Text>The Transformative Learning concentration emphasizes coursework aligned with professional practice in contexts such as education, organizational leadership, and adult education.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transpersonal-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Transpersonal Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transpersonal Psychology</Card.Title>
                                        <Card.Text>The Transpersonal Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, consultation to organizations, career counseling, teaching.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12}>
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/yoga-and-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Integral Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Yoga and Psychology</Card.Title>
                                        <Card.Text>The Yoga and Psychology concentration is intended for master's and doctoral students in the Psychology Program who wish to have more expertise on the relationship between Yoga and Psychology in the context of their professional goals. The concentration emphasizes coursework aligned with professional practice in contexts such as yoga therapy, yoga teaching, psychotherapy practice, somatic education, and somatic research.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>  
        )    
    }
    if( degree === "edu" ) {
        return (
            <div id="discoverConcentrations">
                <Container fluid="lg" className="py-xl-5 py-4 my-xl-5 my-4">
                    <h2 className="mb-4 px-2 px-lg-0">Concentrations</h2>
                    <Carousel variant="dark" className="d-none d-xl-block">
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/business-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Business Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Business Psychology</Card.Title>
                                            <Card.Text>The Business Psychology concentration at Meridian is designed for those who seek to redefine the landscape of organizational behavior and leadership through a lens of group facilitation and psychological depth.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/collective-trauma-and-social-healing-icon.svg" width="80" height="80" className="mb-4" alt="Collective Trauma and Social Healing Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Collective Trauma and Social Healing</Card.Title>
                                            <Card.Text>The Collective Trauma and Social Healing concentration is intended for Master's and Doctoral students in the Psychology and Education programs who aspire to be social healing practitioners in the context of their professional goals.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/developmental-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Developmental Coaching Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Developmental Coaching</Card.Title>
                                            <Card.Text>The Developmental Coaching concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/expressive-and-movement-arts-icon.svg"  width="80" height="80" className="mb-4" alt="Expressive and Movement Arts Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Expressive and Movement Arts</Card.Title>
                                            <Card.Text>The Expressive and Movement Arts concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, employee wellness programs.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/health-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Health and Wellbeing Coaching Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Health and Wellbeing Coaching</Card.Title>
                                            <Card.Text>The Health and Wellbeing Coaching concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, and employee wellness programs.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/organizational-development-icon.svg"  width="80" height="80" className="mb-4" alt="Organizational Development Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Organizational Development</Card.Title>
                                            <Card.Text>The Organizational Development concentration emphasizes coursework aligned with the practice of organizational leadership and development in various institutional domains, like: Business, Non-profits (NGOs); and government including sectors such as education, law, and the arts.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-leadership-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Leadership Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transformative Leadership</Card.Title>
                                            <Card.Text>The Transformative Leadership concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-learning-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Learning Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transformative Learning</Card.Title>
                                            <Card.Text>The Transformative Learning concentration emphasizes coursework aligned with professional practice in contexts such as education, organizational leadership, and adult education.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                    <div className="d-block d-xl-none px-2 px-lg-0">
                        <Row>
                            <Col xs={12} md={6} className='mb-4'>
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/business-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Business Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Business Psychology</Card.Title>
                                        <Card.Text>The Business Psychology concentration at Meridian is designed for those who seek to redefine the landscape of organizational behavior and leadership through a lens of group facilitation and psychological depth.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/collective-trauma-and-social-healing-icon.svg" width="80" height="80" className="mb-4" alt="Collective Trauma and Social Healing Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Collective Trauma and Social Healing</Card.Title>
                                        <Card.Text>The Collective Trauma and Social Healing concentration is intended for Master's and Doctoral students in the Psychology and Education programs who aspire to be social healing practitioners in the context of their professional goals.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/developmental-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Developmental Coaching Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Developmental Coaching</Card.Title>
                                        <Card.Text>The Developmental Coaching concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/expressive-and-movement-arts-icon.svg"  width="80" height="80" className="mb-4" alt="Expressive and Movement Arts Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Expressive and Movement Arts</Card.Title>
                                        <Card.Text>The Expressive and Movement Arts concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, employee wellness programs.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/health-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Health and Wellbeing Coaching Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Health and Wellbeing Coaching</Card.Title>
                                        <Card.Text>The Health and Wellbeing Coaching concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, and employee wellness programs.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/organizational-development-icon.svg"  width="80" height="80" className="mb-4" alt="Organizational Development Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Organizational Development</Card.Title>
                                        <Card.Text>The Organizational Development concentration emphasizes coursework aligned with the practice of organizational leadership and development in various institutional domains, like: Business, Non-profits (NGOs); and government including sectors such as education, law, and the arts.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-leadership-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Leadership Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transformative Leadership</Card.Title>
                                        <Card.Text>The Transformative Leadership concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-learning-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Learning Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transformative Learning</Card.Title>
                                        <Card.Text>The Transformative Learning concentration emphasizes coursework aligned with professional practice in contexts such as education, organizational leadership, and adult education.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div> 
        )
    }
    if( degree === "mba" ) {
        return (
            <div id="discoverConcentrations">
                <Container fluid="lg" className="py-xl-5 py-4 my-xl-5 my-4">
                    <h2 className="mb-4 px-2 px-lg-0">Concentrations</h2>
                    <Carousel variant="dark" className="d-none d-xl-block">
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/business-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Business Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Business Psychology</Card.Title>
                                            <Card.Text>The Business Psychology concentration at Meridian is designed for those who seek to redefine the landscape of organizational behavior and leadership through a lens of group facilitation and psychological depth.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/complexity-management-icon.svg" width="80" height="80" className="mb-4" alt="Complexity Management Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Complexity Management</Card.Title>
                                            <Card.Text>The Complexity Management concentration emphasizes coursework aligned with the practice of strategic and operational management at small, mid, and large scale, across sectors.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/developmental-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Developmental Coaching Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Developmental Coaching</Card.Title>
                                            <Card.Text>The Developmental Coaching concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/generative-entrepreneurship-icon.svg"  width="80" height="80" className="mb-4" alt="Generative Entrepreneurship Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Generative Entrepreneurship</Card.Title>
                                            <Card.Text>The Generative Entrepreneurship concentration emphasizes coursework aligned with developing the capabilities required for launching new entrepreneurial initiatives.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/organizational-development-icon.svg"  width="80" height="80" className="mb-4" alt="Organizational Development Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Organizational Development</Card.Title>
                                            <Card.Text>The Organizational Development concentration emphasizes coursework aligned with the practice of organizational leadership and development in various institutional domains, like: Business, Non-profits (NGOs); and government including sectors such as education, law, and the arts.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-leadership-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Leadership Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transformative Leadership</Card.Title>
                                            <Card.Text>The Transformative Leadership concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                    <div className="d-block d-xl-none px-2 px-lg-0">
                        <Row>
                            <Col xs={12} md={6} className='mb-4'>
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/business-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Business Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Business Psychology</Card.Title>
                                        <Card.Text>The Business Psychology concentration at Meridian is designed for those who seek to redefine the landscape of organizational behavior and leadership through a lens of group facilitation and psychological depth.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/complexity-management-icon.svg" width="80" height="80" className="mb-4" alt="Complexity Management Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Complexity Management</Card.Title>
                                        <Card.Text>The Complexity Management concentration emphasizes coursework aligned with the practice of strategic and operational management at small, mid, and large scale, across sectors.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/developmental-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Developmental Coaching Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Developmental Coaching</Card.Title>
                                        <Card.Text>The Developmental Coaching concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/generative-entrepreneurship-icon.svg"  width="80" height="80" className="mb-4" alt="Generative Entrepreneurship Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Generative Entrepreneurship</Card.Title>
                                        <Card.Text>The Generative Entrepreneurship concentration emphasizes coursework aligned with developing the capabilities required for launching new entrepreneurial initiatives.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/organizational-development-icon.svg"  width="80" height="80" className="mb-4" alt="Organizational Development Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Organizational Development</Card.Title>
                                        <Card.Text>The Organizational Development concentration emphasizes coursework aligned with the practice of organizational leadership and development in various institutional domains, like: Business, Non-profits (NGOs); and government including sectors such as education, law, and the arts.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-leadership-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Leadership Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transformative Leadership</Card.Title>
                                        <Card.Text>The Transformative Leadership concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div> 
        )
    }
    if(degree === "all") {
        return (
            <div id="discoverConcentrations">
                <Container fluid="lg" className="py-xl-5 py-4 my-5">
                    <h2 className="mb-4 px-2 px-lg-0">Concentrations</h2>
                    <Carousel variant="dark" className="d-none d-xl-block">
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/business-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Business Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Business Psychology</Card.Title>
                                            <Card.Text>The Business Psychology concentration at Meridian is designed for those who seek to redefine the landscape of organizational behavior and leadership through a lens of group facilitation and psychological depth.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/clinical-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Clinical Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Clinical Psychology</Card.Title>
                                            <Card.Text>The Clinical Psychology concentration emphasizes coursework aligned with the practice of psychotherapy in contexts such as community clinics, private practice, employee assistance programs, and forensic contexts.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/collective-trauma-and-social-healing-icon.svg" width="80" height="80" className="mb-4" alt="Collective Trauma and Social Healing Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Collective Trauma and Social Healing</Card.Title>
                                            <Card.Text>The Collective Trauma and Social Healing concentration is intended for Master's and Doctoral students in the Psychology and Education programs who aspire to be social healing practitioners in the context of their professional goals.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/complexity-management-icon.svg" width="80" height="80" className="mb-4" alt="Complexity Management Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Complexity Management</Card.Title>
                                            <Card.Text>The Complexity Management concentration emphasizes coursework aligned with the practice of strategic and operational management at small, mid, and large scale, across sectors.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/depth-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Depth Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Depth Psychology</Card.Title>
                                            <Card.Text>The Depth Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, depth consultation in organizations, career counseling, teaching.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/developmental-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Developmental Coaching Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Developmental Coaching</Card.Title>
                                            <Card.Text>The Developmental Coaching concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>                                
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/expressive-and-movement-arts-icon.svg"  width="80" height="80" className="mb-4" alt="Expressive and Movement Arts Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Expressive and Movement Arts</Card.Title>
                                            <Card.Text>The Expressive and Movement Arts concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, employee wellness programs.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/generative-entrepreneurship-icon.svg"  width="80" height="80" className="mb-4" alt="Generative Entrepreneurship Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Generative Entrepreneurship</Card.Title>
                                            <Card.Text>The Generative Entrepreneurship concentration emphasizes coursework aligned with developing the capabilities required for launching new entrepreneurial initiatives.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/health-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Health and Wellbeing Coaching Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Health and Wellbeing Coaching</Card.Title>
                                            <Card.Text>The Health and Wellbeing Coaching concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, and employee wellness programs.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/integral-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Integral Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Integral Psychology</Card.Title>
                                            <Card.Text>The Integral Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, depth consultation in organizations, career counseling, and teaching.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/organizational-development-icon.svg" width="80" height="80" className="mb-4" alt="Organizational Development Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Organizational Development</Card.Title>
                                            <Card.Text>The Organizational Development concentration emphasizes coursework aligned with the practice of organizational leadership and development in various institutional domains, like: Business, Non-profits (NGOs); and government including sectors such as education, law, and the arts.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/somatic-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Somatic Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Somatic Psychology</Card.Title>
                                            <Card.Text>The Somatic Psychology concentration emphasizes coursework aligned with professional practice in contexts such as somatic education, psychotherapy practice, Health and Wellbeing Coaching, employee wellness programs. The approach to learning somatic psychology at Meridian University is rooted in the transformative learning paradigm at the heart of Meridian’s programs, with a particular emphasis on embodied approaches to teaching and learning.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-leadership-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Leadership Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transformative Leadership</Card.Title>
                                            <Card.Text>The Transformative Leadership concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-learning-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Learning Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transformative Learning</Card.Title>
                                            <Card.Text>The Transformative Learning concentration emphasizes coursework aligned with professional practice in contexts such as education, organizational leadership, and adult education.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transpersonal-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Transpersonal Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Transpersonal Psychology</Card.Title>
                                            <Card.Text>The Transpersonal Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, consultation to organizations, career counseling, teaching.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/yoga-and-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Integral Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Yoga and Psychology</Card.Title>
                                            <Card.Text>The Yoga and Psychology concentration is intended for master's and doctoral students in the Psychology Program who wish to have more expertise on the relationship between Yoga and Psychology in the context of their professional goals. The concentration emphasizes coursework aligned with professional practice in contexts such as yoga therapy, yoga teaching, psychotherapy practice, somatic education, and somatic research.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                    <div className="d-block d-xl-none px-2 px-lg-0">
                        <Row>
                            <Col xs={12} md={6} className='mb-4'>
                                    <Card>
                                        <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/business-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Business Psychology Icon" />
                                        <Card.Body>
                                            <Card.Title className="mb-3">Business Psychology</Card.Title>
                                            <Card.Text>The Business Psychology concentration at Meridian is designed for those who seek to redefine the landscape of organizational behavior and leadership through a lens of group facilitation and psychological depth.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/clinical-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Clinical Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Clinical Psychology</Card.Title>
                                        <Card.Text>The Clinical Psychology concentration emphasizes coursework aligned with the practice of psychotherapy in contexts such as community clinics, private practice, employee assistance programs, and forensic contexts.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/collective-trauma-and-social-healing-icon.svg" width="80" height="80" className="mb-4" alt="Collective Trauma and Social Healing Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Collective Trauma and Social Healing</Card.Title>
                                        <Card.Text>The Collective Trauma and Social Healing concentration is intended for Master's and Doctoral students in the Psychology and Education programs who aspire to be social healing practitioners in the context of their professional goals.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/complexity-management-icon.svg" width="80" height="80" className="mb-4" alt="Complexity Management Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Complexity Management</Card.Title>
                                        <Card.Text>The Complexity Management concentration emphasizes coursework aligned with the practice of strategic and operational management at small, mid, and large scale, across sectors.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/depth-psychology-icon.svg" width="80" height="80" className="mb-4" alt="Depth Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Depth Psychology</Card.Title>
                                        <Card.Text>The Depth Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, depth consultation in organizations, career counseling, teaching.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/developmental-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Developmental Coaching Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Developmental Coaching</Card.Title>
                                        <Card.Text>The Developmental Coaching concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>                                
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/expressive-and-movement-arts-icon.svg"  width="80" height="80" className="mb-4" alt="Expressive and Movement Arts Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Expressive and Movement Arts</Card.Title>
                                        <Card.Text>The Expressive and Movement Arts concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, employee wellness programs.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/generative-entrepreneurship-icon.svg"  width="80" height="80" className="mb-4" alt="Generative Entrepreneurship Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Generative Entrepreneurship</Card.Title>
                                        <Card.Text>The Generative Entrepreneurship concentration emphasizes coursework aligned with developing the capabilities required for launching new entrepreneurial initiatives.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/health-coaching-icon.svg"  width="80" height="80" className="mb-4" alt="Health and Wellbeing Coaching Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Health and Wellbeing Coaching</Card.Title>
                                        <Card.Text>The Health and Wellbeing Coaching concentration emphasizes coursework aligned with professional practice in contexts such as conventional healthcare settings, coaching practices, and employee wellness programs.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/integral-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Integral Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Integral Psychology</Card.Title>
                                        <Card.Text>The Integral Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, depth consultation in organizations, career counseling, and teaching.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/organizational-development-icon.svg" width="80" height="80" className="mb-4" alt="Organizational Development Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Organizational Development</Card.Title>
                                        <Card.Text>The Organizational Development concentration emphasizes coursework aligned with the practice of organizational leadership and development in various institutional domains, like: Business, Non-profits (NGOs); and government including sectors such as education, law, and the arts.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/somatic-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Somatic Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Somatic Psychology</Card.Title>
                                        <Card.Text>The Somatic Psychology concentration emphasizes coursework aligned with professional practice in contexts such as somatic education, psychotherapy practice, Health and Wellbeing Coaching, employee wellness programs. The approach to learning somatic psychology at Meridian University is rooted in the transformative learning paradigm at the heart of Meridian’s programs, with a particular emphasis on embodied approaches to teaching and learning.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-leadership-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Leadership Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transformative Leadership</Card.Title>
                                        <Card.Text>The Transformative Leadership concentration emphasizes coursework aligned with practice in contexts such as executive coaching, developmental assessment, and management of deliberately developmental organizations.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transformative-learning-icon.svg"  width="80" height="80" className="mb-4" alt="Transformative Learning Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transformative Learning</Card.Title>
                                        <Card.Text>The Transformative Learning concentration emphasizes coursework aligned with professional practice in contexts such as education, organizational leadership, and adult education.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/transpersonal-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Transpersonal Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Transpersonal Psychology</Card.Title>
                                        <Card.Text>The Transpersonal Psychology concentration emphasizes coursework aligned with professional practice in contexts such as psychotherapy, coaching, consultation to organizations, career counseling, teaching.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Card>
                                    <Card.Img src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/yoga-and-psychology-icon.svg"  width="80" height="80" className="mb-4" alt="Integral Psychology Icon" />
                                    <Card.Body>
                                        <Card.Title className="mb-3">Yoga and Psychology</Card.Title>
                                        <Card.Text>The Yoga and Psychology concentration is intended for master's and doctoral students in the Psychology Program who wish to have more expertise on the relationship between Yoga and Psychology in the context of their professional goals. The concentration emphasizes coursework aligned with professional practice in contexts such as yoga therapy, yoga teaching, psychotherapy practice, somatic education, and somatic research.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>  
        )    
    }
}

export default DiscoverConcentrations