import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import './styles/discoverNavigation.scss'

const DiscoverNavigation = () => {

    const beginDate = "Dec. 1st, 2024";
    const deadlineDate = "Nov. 5, 2024";

    return (
        <Navbar expand="lg" id="discover">
            <Container fluid="lg" className="justify-content-between">
                <Navbar.Brand  className="d-inline-block discover">
                <img
                    src="https://web-cdn.meridianuniversity.edu/logos/meridian-university-logo.svg"
                    width="146"
                    height="64"
                    className="d-inline-block align-top nav-logo"
                    alt="Meridian University Logo"
                />
                </Navbar.Brand>
                <div className="d-flex mx-auto mx-sm-0">
                    <div id="termInformation">
                         <div className='next-op border-end'>
                            <p className="text">
                                Next<br />Opportunity
                            </p>
                         </div>
                         <div className='deadline'>
                            <p className="text">
                                Deadline
                            </p>
                            <p className="date">
                                {deadlineDate}
                            </p>
                         </div>
                         <div className='begin'>
                            <p className="text">
                                Begins
                            </p>
                            <p className="date">
                                {beginDate}
                            </p>
                         </div>
                    </div>
                </div>
            </Container>
        </Navbar>
    )
}

export default DiscoverNavigation