import React from "react"
import { useSiteMetadata } from "./helpers/use-site-metadata"

export const Seo = ({ title, description, pathname, children, cardImage, ogImage, pageType }) => {
    const { title: defaultTitle, description: defaultDesctiption, cdnUrl, siteUrl, twitterUsername, cardImage: defaultCardImage, ogImage: defaultOGImage } = useSiteMetadata()



    const seo = {
        title: title || defaultTitle,
        description: description || defaultDesctiption,
        cardImage: cardImage ? `${cdnUrl}${cardImage}` : `${cdnUrl}${defaultCardImage}`,
        ogImage: ogImage ? `${cdnUrl}${ogImage}` : `${cdnUrl}${defaultOGImage}`,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername,
        type: pageType ? pageType : "website",
    }

    const defaultSchema = {
        "@context": "https://schema.org",
        "@type": "CollegeOrUniversity",
        "@id": `${siteUrl}`,
        "name": "Meridian University",
        "url": `${siteUrl}${pathname}`,
        "logo": "https://web-cdn.meridianuniversity.edu/logos/meridian-university-logo.svg",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1(833)256-2295",
            "contactType": "Customer service"
        },
        "sameAs": [
            "https://www.facebook.com/MeridianUniversity",
            "https://www.twitter.com/meridian_u",
            "https://www.youtube.com/user/meridianprograms",
            "https://www.instagram.com/{meridianuniversity}",
            "https://www.linkedin.com/school/{meridianuniversity}",
            "https://www.tiktok.com/@meridianuniversity",
            "https://www.instagram.com/meridian.university/?hl=en",
            "https://www.linkedin.com/school/meridian-university/"
        ],
        "address": [
            {
                "@type": "PostalAddress",
                "streetAddress": "47 Sixth Street",
                "addressLocality": "Petaluma",
                "addressRegion": "California",
                "postalCode": "94952",
                "addressCountry": "USA"
            },
            {
                "@type": "PostalAddress",
                "streetAddress": "2450 Colorado Avenue",
                "addressLocality": "Santa Monica",
                "addressRegion": "California",
                "postalCode": "90404",
                "addressCountry": "USA"
            },
            {
                "@type": "PostalAddress",
                "streetAddress": "Ermou 56",
                "addressLocality": "Athens",
                "postalCode": "10563",
                "addressCountry": "Greece"
            },
            {
                "@type": "PostalAddress",
                "streetAddress": "Greifswalder Strasse 226",
                "addressLocality": "Berlin",
                "postalCode": "10405",
                "addressCountry": "Germany"
            },
            {
                "@type": "PostalAddress",
                "streetAddress": "5th St",
                "addressLocality": "Johannesburg",
                "postalCode": "2196",
                "addressCountry": "South Africa"
            },
            {
                "@type": "PostalAddress",
                "streetAddress": "501 Middle Yincheng Road",
                "addressLocality": "Shanghai",
                "postalCode": "200120",
                "addressCountry": "China"
            },          
        ]
    }

    const courseSchema = {
            "@context": "https://schema.org",
            "@type": "Course",
            "name": `${title}`,
            "url": `${siteUrl}${pathname}`,
            "description": `${description}`,
            "provider": {
              "@type": "Organization",
              "name": "Meridian University",
              "sameAs": `${siteUrl}`
            }      
    }

    return (
        <>
            <title>{seo.title} | Meridian University</title>
            <meta name="description" content={seo.description} />
            <link rel="canonical" href={seo.url} />
            {/* Open Graph Meta */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${seo.title} | Meridian University`} />
            <meta property="og:url" content={seo.url} />
            <meta property="og:image" content={seo.ogImage} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:description" content={seo.description} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content="Meridian University" />
            {/* Twitter Meta */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name='twitter:site' content={seo.twitterUsername} />
            <meta name='twitter:title' content={`${seo.title} | Meridian University`} />
            <meta name='twitter:description' content={seo.description} />
            <meta name='twitter:image' content={seo.cardImage} />
            <script type="application/ld+json">{JSON.stringify(defaultSchema)}</script>
            { pageType === "course" ? 
                <script type="application/ld+json">{JSON.stringify(courseSchema)}</script>  
                :
                null
            }
            {children}
        </>
    )
}

export default Seo